import React, {useState} from "react";

export const LoaderContext = React.createContext(0);

export const LoaderProvider = ({value, children}) => {

    const [loadingCounter, setLoadingCounter] = useState(0)
    var numChiamate = 0;

    const loading = () => {
        return loadingCounter > 0;
    }

    const resetSpinner = () => {
        numChiamate = 0;
        setLoadingCounter(numChiamate);
    }

    const startChiamata = () => {
        numChiamate++;
        setLoadingCounter(numChiamate);
    }

    const endChiamata = () => {
        numChiamate = numChiamate - 1 >= 0 ? numChiamate - 1 : 0;
        setLoadingCounter(numChiamate);
    }

    return (
        <LoaderContext.Provider value={{loading, resetSpinner, startChiamata, endChiamata}}>{children}</LoaderContext.Provider>
    );
};
