import {Button, Modal} from "react-bootstrap";
import Translate from "./Translate";
import {useContext} from "react";
import {TranslateContext} from "../context/TranslateContext";

function ModalConfirm(props) {

    const {traduci} = useContext(TranslateContext)

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header
                className={'cmms-modal-header'}
                closeVariant='white'
                closeButton>
                <Modal.Title>{props.titolo || traduci('ATTENZIONE')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{props.testo}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    <Translate>Annulla</Translate>
                </Button>
                <Button variant="primary" onClick={props.handleOk}>
                    <Translate>Conferma</Translate>
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalConfirm;