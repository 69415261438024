import {useFormik} from "formik";
import axios from "axios";
import {useParams} from "react-router-dom";
import ModalMsg from "../../components/ModalMsg";
import React, {useContext, useState} from "react";
import {getUtenteFromToken} from "../../utility/common";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";

function AttivaAccount() {
    const {token} = useParams()

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const closeModal = () => setShow(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            password2: ''
        }, onSubmit: async values => {
            if (values.password === values.password2) {
                let utente = getUtenteFromToken(token);
                if (utente && utente.id && utente.id !== '') {
                    startChiamata()
                    await axios
                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/" + utente.id + "/password/",
                            values, {
                                headers: {
                                    jwt: token
                                }
                            })
                        .then((response) => {
                            setModalMsg(response.data.description);
                            setShow(true);
                            endChiamata()
                        });
                }
            } else {
                setModalMsg('La Password e la Conferma password devono avere lo stesso valore');
                setShow(true);
            }
        }
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className="cmms-login cmms-recupera-pwd">
                <Card
                    icon={'fas fa-key'}
                    title={'Completa la registrazione scegliendo la password e cliccando sul bottone Attiva'}
                    subtitle={'Una volta attivato l\'account, potrai accedere ai nostri servizi'}>
                    <form className="row g-3 needs-validation"
                          method="post"
                          noValidate=""
                          onSubmit={formik.handleSubmit}>
                        <div className="col-12">
                            <label htmlFor="password" className="form-label">Password*</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                className="form-control"
                                required/>
                            <div className="invalid-feedback">
                                La Password deve essere valorizzata.
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="password2" className="form-label">Conferma password*</label>
                            <input
                                id="password2"
                                name="password2"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password2}
                                className="form-control"
                                required/>
                            <div className="invalid-feedback">
                                La Conferma Password deve essere valorizzata.
                            </div>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" name="conferma" id="conferma"
                                    className="btn btn-dark"
                                    disabled={formik.values.password === '' || formik.values.password2 === '' || formik.values.password !== formik.values.password2}>
                                <i className="fas fa-check" /> Conferma
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default AttivaAccount;
