import React, {Fragment, useContext, useState} from "react";
import Menu from "./components/Menu";
import ModalMsg from "./components/ModalMsg";
import {LoaderContext} from "./context/LoaderContext";
import Spinner from "./components/Spinner";
import {Route, Switch, useHistory} from "react-router-dom";
import ResetPassword from "./pages/login/recupera_pwd/ResetPassword";
import AttivaAccount from "./pages/login/AttivaAccount";
import RecuperaPwd from "./pages/login/recupera_pwd/RecuperaPwd";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/home/Home";
import ConfUtenti from "./pages/configura/utenti/ConfUtenti";
import ModificaUtente from "./pages/configura/utenti/ModificaUtente";
import Login from "./pages/login/Login";
import Footer from "./components/Footer";
import axios from "axios";
import {TranslateContext} from "./context/TranslateContext";
import {AuthContext} from "./context/AuthContext";
import ConfInstallazioni from "./pages/configura/installazioni/ConfInstallazioni";
import DashboardStatiInstallazioni from "./pages/home/DashboardStatiInstallazioni";
import ModificaInstallazione from "./pages/configura/installazioni/ModificaInstallazione";
import ModificaInstallazionePortal from "./pages/configura/installazioni/ModificaInstallazionePortal";
import InfoCharts from "./pages/home/InfoCharts";
import ConfPartners from "./pages/configura/partners/Confpartners";
import ModificaPartner from "./pages/configura/partners/ModificaPartner";

function ApplicationBody() {

    const [show, setShow] = useState(false);

    const history = useHistory()

    const {getLingua} = useContext(TranslateContext);
    const {resetSpinner, loading} = useContext(LoaderContext);
    const {handleLogout} = useContext(AuthContext);

    // For GET requests
    axios.interceptors.request.use(
        (req) => {
            req.headers['Content-Type'] = 'application/json';

            const token = localStorage.getItem('cmms-login');
            if (token && token !== '') {
                req.headers['jwt'] = token;
            }

            let lang = getLingua();
            if (lang && lang !== '') {
                req.headers['lang'] = lang;
            }
            return req;
        },
        (err) => {
            setShow(true)
            resetSpinner()
            return Promise.reject(err);
        }
    );

    axios.interceptors.response.use(
        (res) => {

            // Token scaduto o non valido
            if (res.data && res.data.result && (res.data.result === -100 || res.data.result === -101)) {
                console.log('Token non più valido')

                // Logout utente
                handleLogout()

                history.push("/login");
            }

            if (res.status !== 201) {
                // Esempio
            }
            return res;
        },
        (err) => {
            setShow(true)
            resetSpinner()
            return Promise.reject(err)
        }
    );

    const closeModal = () => setShow(false);

    return (
        <Fragment>
            <Menu/>
            <div className={'container-fluid'}>
                <ModalMsg
                    show={show}
                    handleClose={closeModal}
                    testo={"Si è verificato un errore. Riprovare più tardi."}/>

                {/* Gestione spinner di caricamento */}
                <Spinner loading={loading()} />

                <div className="cmms-container">
                    <Switch>
                        <Route path="/reset-password/:token"
                               render={() => <ResetPassword />}/>
                        <Route path="/attiva-account/:token"
                               render={() => <AttivaAccount />}/>
                        <Route path="/recuperaPwd" render={() => <RecuperaPwd />}/>
                        <PrivateRoute path="/home" component={Home}/>
                        <PrivateRoute path="/conf-utenti" component={ConfUtenti}/>
                        <PrivateRoute path="/upd-utente/:id?" component={ModificaUtente}/>
                        <PrivateRoute path="/conf-installazioni" component={ConfInstallazioni}/>
                        <PrivateRoute path="/upd-installazione/:id?" component={ModificaInstallazione}/>
                        <PrivateRoute path="/dashboard" component={DashboardStatiInstallazioni}/>
                        <PrivateRoute path="/charts-installazione/:id/:descrizione" component={InfoCharts}/>
                        <PrivateRoute path="/upd-installazione-portal/:id?" component={ModificaInstallazionePortal}/>
                        <PrivateRoute path="/conf-partners" component={ConfPartners}/>
                        <PrivateRoute path="/upd-partner/:id?" component={ModificaPartner}/>



                        {/* Default: Login */}
                        <Route render={() => <Login/>}/>
                    </Switch>
                </div>
            </div>
            <Footer/>
        </Fragment>
    )
}

export default ApplicationBody;