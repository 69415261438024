import React, {useState} from "react";
import {getUtenteFromToken} from "../utility/common";

export const AuthContext = React.createContext('');

export const AuthProvider = ({value, children}) => {

    const [state, setState] = useState(() => {
        const token = localStorage.getItem("cmms-login");
        let user = token && token !== '' ? getUtenteFromToken(token) : null;
        return {inizializing: !user, user}
    })

    const handleLogin = (user, history, hash) => {
        setState({initializing: false, user})
        if (hash && hash !== '') {
            history.push(hash)
        } else {
            history.push('/home')
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('cmms-login');
        setState({initializing: false, user: null})
    }

    const isLogged = () => {
        return state.user && state.user !== '';
    }

    return (
        <AuthContext.Provider
            value={{user: state.user, handleLogin, handleLogout, isLogged}}>{children}</AuthContext.Provider>
    );
};
