import Accesso from "./Accesso";
import {useHistory, withRouter} from 'react-router-dom';
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";

function Login(props) {

    var hash = props?.location?.state?.hash || ''
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const isLoggedIn = authContext.isLogged();

    if (isLoggedIn) {
        history.push("/home");
    }

    return (
        <div className="cmms-login">
            <Accesso hash={hash}/>
        </div>
    );
}

export default withRouter(Login);
