import axios from "axios";
import {Fragment, useContext, useEffect, useState} from "react";
import {
    Link, useLocation
} from "react-router-dom";
import {LoaderContext} from "../context/LoaderContext";
import {AuthContext} from "../context/AuthContext";
import {RuoliContext} from "../context/RuoliContext";
import Translate from "./Translate";

function Menu() {

    const {user, handleLogout} = useContext(AuthContext);
    const location = useLocation();

    // standard per recuperare parametri al caricamento
    useEffect(() => {
    }, []);

    return (
        <nav className="navbar navbar-expand-md navbar-light fixed-top">
            <div className="container-fluid">
                <Link to="/home"><img src={"logo.svg"} className={"cmms-logo-icon"} alt="Manù Logo"></img></Link>
                {
                    location.pathname != '/'
                    && location.pathname != '/recuperaPwd'
                    && location.pathname != '/reset-password'
                    && location.pathname != '/attiva-account' ?
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button> : ''
                }
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    {
                        user ? (
                            <ul className="navbar-nav menu-centro">
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/home">
                                        <i className="fas fa-home"/>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="utenti-menu"
                                        role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-cogs"/> <Translate>Configura</Translate>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="utenti-menu">
                                        <li>
                                            <Link to="/conf-utenti" className="dropdown-item">
                                                <i className="fas fa-user"/>
                                                <Translate>Utenti</Translate>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/conf-installazioni" className="dropdown-item">
                                                <i className="fas fa-cogs"/>
                                                <Translate>Installazioni</Translate>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/conf-partners" className="dropdown-item">
                                                <i className="fas fa-users"/>
                                                <Translate>Partners</Translate>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        ) : <ul className="navbar-nav menu-centro"/>
                    }
                    <div className="d-flex cmms-utente-info">
                        {
                            user ?
                                <Fragment>
                                    <div>
                                    <span className="pe-2"><i className="fas fa-user-lock pe-1"/>
                                        {
                                            user.nome
                                        }
                                    </span>
                                        <Link to="/" className="btn btn-dark" onClick={() => handleLogout()}>
                                            <i className="fa-solid fa-right-from-bracket"/>
                                        </Link>
                                    </div>
                                </Fragment>
                                : ''
                        }
                    </div>
                </div>

            </div>
            
        </nav>
    );
}

export default Menu;
