import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import DashboardStatiInstallazioni from "./DashboardStatiInstallazioni";

function Home() {

    const [dashboardAssegnati, setDashboardAssegnati] = useState(false);

    // carico riferimento al contesto in modo da poterlo usare correttamente in useEffect senza avere problemi di dipendenze non dichiarate
    const loader = useRef(useContext(LoaderContext));

    useEffect(() => {
    }, []);

    return (
        <div className="cmms-home">
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <DashboardStatiInstallazioni/>
                </div>
            </div>
        </div>
    )
}

export default Home;
