import React, {useContext} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {AuthContext} from "../context/AuthContext";

const PrivateRoute = ({component: Component, ...rest}) => {

    const authContext = useContext(AuthContext);
    const isLoggedIn = authContext.isLogged();

    var hash = document.location.hash || ''
    if (hash) {
        hash = hash.replaceAll('#', '')
    }

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/login', state: {
                            from: props.location,
                            hash: hash
                        }
                    }}/>
                )
            }
        />
    )
}

export default PrivateRoute