import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";

import {Link} from "react-router-dom";
import {getTimestampFormattato} from "../../../utility/common";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import Pager from "../../../components/Pager";
import DataTableCmms from "../../../components/tabelle/NsDataTable";

function ConfUtenti() {

    const [filtro, setFiltro] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [utenteSel, setUtenteSel] = useState(null);
    const [show, setShow] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();

    let debounce = null;

    // carico riferimento al contesto in modo da poterlo usare correttamente in useEffect senza avere problemi di dipendenze non dichiarate
    const loader = useRef(useContext(LoaderContext));

    useEffect(() => {
        let f = '?pag=' + pagina;
        if (filtro && filtro !== '') {
            f += '&filtro=' + filtro;
        }

        loader.current.startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/" + f).then((response) => {
            setData(response.data.data);
            setTotPagine(response.data.pages)
            setLoadingData(false);
            loader.current.endChiamata();
        });
    }, [filtro, pagina]);

    const openModalDel = (utente) => {
        setUtenteSel(utente)
        setShow(true);
    }

    const closeModalDel = () => setShow(false);

    const eliminaUtente = () => {
        setShow(false);
        loader.current.startChiamata();
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/" + utenteSel.id).then((response) => {
            setData(response.data.data);
            loader.current.endChiamata();
        });
    }

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    const handleFiltroChange = (event) => {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
            setFiltro(event.target.value);
        }, 800)
    }

    const columns = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Cognome',
            selector: row => row.cognome,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.mail,
            sortable: true,
        },
        {
            name: 'Data di registrazione',
            selector: row => getTimestampFormattato(row.registrazione),
            sortable: true,
        },
        {
            name: 'Attivo',
            selector: row => row.attivo === 1 ?
                <span className={"cmms-span--verde"}>
                    <i className="fas fa-user-check"/>
                </span> :
                <span className={"cmms-span--rosso"}>
                    <i className="fas fa-user-times"/>
                </span>,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.attivo < b.attivo) {
                    return -1;
                }

                if (a.attivo > b.attivo) {
                    return 1;
                }

                return 0;
            }
        },
        {
            name: '',
            selector: row => <Fragment>
                <Link to={"/upd-utente/" + row.id}
                      className="btn btn-dark cmms-btn-table"
                      data-toggle="tooltip" title="Modifica">
                    <i className="fas fa-pen"/> <span className={'cmms-display-mobile'}>Modifica</span>
                </Link>
                <button
                    className="btn btn-danger cmms-btn-table"
                    disabled={row.sys === 1}
                    data-toggle="tooltip" title="Bagnetto?" onClick={() => {
                    openModalDel(row)
                }}>
                    <i className="fas fa-trash"/> <span className={'cmms-display-mobile'}>Elimina</span>
                </button>
            </Fragment>,
            sortable: false,
            right: true,
        }
    ];

    const getComponenteFiltro = () => {
        return <input
            type={"text"}
            id={"filtro"}
            placeholder={"Nome/Cognome/E-Mail"}
            className="cmms-filtro-data-table"
            onChange={handleFiltroChange}/>
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-user'}
                title={'Utenti'}
                getBottoniDx={() =>
                    <Link to="/upd-utente" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> <span className={'cmms-display-mobile'}>Aggiungi</span>
                    </Link>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={closeModalDel}
                    handleOk={eliminaUtente}
                    testo={"Sicuro di voler eliminare l'utente: " + (utenteSel
                        && (utenteSel.nome + ' ' + utenteSel.cognome)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <DataTableCmms
                            data={data}
                            columns={columns}
                            addPagination={false}
                            componenteFiltro={getComponenteFiltro}
                        >
                        </DataTableCmms>
                    )}
                {
                    totPagine ?
                        (
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={cambioPagina}
                            >
                            </Pager>
                        ) : ''
                }
            </Card>
        </CardContainer>
    );
}

export default ConfUtenti;
