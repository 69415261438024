import React from "react";
import {getAnni, getAnno, mesiIt} from "../utility/common";
import {it} from "date-fns/locale";
import DatePicker, {registerLocale} from "react-datepicker";

registerLocale('it', it)

function NsDatePicker({
                          nome,
                          dataSelezionata,
                          setSelectedDate,
                          maxDate = null,
                          minDate = null,
                          anniStart = null,
                          anniEnd = 0,
                      }) {

    const years = getAnni(anniStart, anniEnd);

    return <DatePicker
        id={nome}
        name={nome}
        showYearDropdown={true}
        maxDate={maxDate}
        minDate={minDate}
        renderCustomHeader={({
                                 date,
                                 changeYear,
                                 changeMonth,
                                 decreaseMonth,
                                 increaseMonth,
                                 prevMonthButtonDisabled,
                                 nextMonthButtonDisabled,
                             }) => (
            <div
                style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button type={"button"} className={'btn btn-sm'} onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}>
                    <i className="fas fa-chevron-left"></i>
                </button>

                <select
                    value={mesiIt[date.getMonth()]}
                    className={'form-select--sm'}
                    onChange={({target: {value}}) =>
                        changeMonth(mesiIt.indexOf(value))
                    }
                >
                    {mesiIt.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <select
                    value={getAnno(date)}
                    className={'form-select--sm'}
                    onChange={({target: {value}}) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <button type={"button"} className={'btn btn-sm'} onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}>
                    <i className="fas fa-chevron-right"></i>
                </button>
            </div>
        )}
        locale={it}
        selected={dataSelezionata && new Date(dataSelezionata)}
        onChange={(date, e) => {
            setSelectedDate(date, e)
        }}
        onSelect={(date, e) => {
            setSelectedDate(date, e)
        }}
        canBeDisabled={true}
        className="form-control"
        autoComplete="off"
        dateFormat={"dd/MM/yyyy"}
    />
}

export default NsDatePicker;