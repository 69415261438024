import CardLicenza from '../info/CardLicenza';
import { getDataFormattata, dateToMilliseconds } from '../../utility/common';
import GaugeChart from 'react-gauge-chart';
import Card from "../../components/Card";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { LoaderContext } from '../../context/LoaderContext';
import axios from 'axios';
import ModificaInstallazionePortal from '../configura/installazioni/ModificaInstallazionePortal';

import { scadenza_warning } from '../../utility/common';

export default function InfoCharts() {
    const { id, descrizione } = useParams();

    const [installazione, setInstallazione] = useState([]);
    const [datiLicenza, setDatiLicenza] = useState([]);

    const { startChiamata, endChiamata } = useContext(LoaderContext);

    async function getData() {
        startChiamata()
        await axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/" + id)
            .then((response) => {
                if (response.data.data) {
                    setInstallazione(response.data.data);
                    let endpoint = response.data.data.endpoint;

                    axios.get(endpoint + "api/cmms/info/licenza/").then((response) => {
                        let dataInst = response.data;
                        if (dataInst) {
                            setDatiLicenza(dataInst);
                        }
                        endChiamata()
                    });
                }
                endChiamata()
            });
    }

    useEffect(() => {
        getData();
    }, [id]);

    return (
        <Card
            icon={'fas fa-info-circle'}
            title={'Informazioni'}
        >
            {
                datiLicenza ?
                    <>                        
                    <div className='text-center pt-4 pb-3 licenza-title'>
                        <strong>{descrizione}</strong> (versione {datiLicenza.versione} )  Licenza valida fino a data:
                        <span className={`${(dateToMilliseconds(datiLicenza.data_scadenza) - dateToMilliseconds(Date.now()) < 0) ? 'text-danger' : ((dateToMilliseconds(datiLicenza.data_scadenza) - dateToMilliseconds(Date.now())) < scadenza_warning(1)) ? 'text-warning' : ''}`}> <strong>{getDataFormattata(datiLicenza.data_scadenza)}</strong></span>
                    </div>
                        {/* <div className='d-flex justify-content-between'> */}

                            <div className="container-fluid">
                                <div className='row'>
                                    <div className={"col-12 col-md-5 cmms-dati-licenza "}>

                                        <div className={'row d-flex text-center'}>

                                            <div className='col-12'>
                                                <label htmlFor='chart-portal'>
                                                    <div id='chart-portal' className='col-12 '>
                                                        <GaugeChart
                                                            id="spazioDisco"
                                                            hideText={true}
                                                            nrOfLevels={50}
                                                            percent={datiLicenza && datiLicenza.spazio_libero_perc ? (datiLicenza.spazio_libero_perc / 100) : 0}
                                                            arcPadding={0.02}
                                                            needleColor="black"

                                                        />
                                                    </div>
                                                    <div className='text-center'>Spazio utilizzato:
                                                        <span className={`${datiLicenza.max_space_disk - datiLicenza.spazio_libero_gb === 0 ? 'text-warning fw-bold' : datiLicenza.max_space_disk - datiLicenza.spazio_libero_gb < 0 ? 'text-danger fw-bold' : ''}`}> <strong>{datiLicenza.spazio_libero_txt}</strong> su <strong>{datiLicenza.max_space_disk} GB</strong></span></div>
                                                </label>
                                            </div>

                                        </div>

                                        <div className='container mx-3'>
                                            <div className='row '>

                                                <div className='col-md-6 col-12 p-2'>
                                                    <CardLicenza
                                                        titolo={"Super Amministratore"}
                                                        num={datiLicenza.num_superad_attivi}
                                                        tot={datiLicenza.num_superad}
                                                        nome_class={`${datiLicenza.num_superad - datiLicenza.num_superad_attivi === 0 ? 'text-warning fw-bold' : datiLicenza.num_superad - datiLicenza.num_superad_attivi < 0 ? 'text-danger fw-bold' : ''}`}
                                                    />
                                                </div>
                                                <div className={'col-md-6 col-12 p-2'}>
                                                    <CardLicenza
                                                        titolo={"Amministratore"}
                                                        num={datiLicenza.num_admin_attivi}
                                                        tot={datiLicenza.num_admin}
                                                        nome_class={`${datiLicenza.num_admin - datiLicenza.num_admin_attivi === 0 ? 'text-warning fw-bold' : datiLicenza.num_admin - datiLicenza.num_admin_attivi < 0 ? 'text-danger fw-bold' : ''}`}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='container mx-3'>

                                            <div className='row'>

                                                <div className={'col-md-6 col-12 p-2'}>
                                                    <CardLicenza
                                                        titolo={"Tecnico"}
                                                        num={datiLicenza.num_tecnici_attivi}
                                                        tot={datiLicenza.num_tecnici}
                                                        nome_class={`${datiLicenza.num_tecnici - datiLicenza.num_tecnici_attivi === 0 ? 'text-warning fw-bold' : datiLicenza.num_tecnici - datiLicenza.num_tecnici_attivi < 0 ? 'text-danger fw-bold' : ''}`}
                                                    />
                                                </div>
                                                <div className={'col-md-6 col-12 p-2'}>
                                                    <CardLicenza
                                                        titolo={"Guest"}
                                                        num={datiLicenza.num_guest_attivi}
                                                        tot={datiLicenza.num_guest}
                                                        nome_class={`${datiLicenza.num_guest - datiLicenza.num_guest_attivi === 0 ? 'text-warning fw-bold' : datiLicenza.num_guest - datiLicenza.num_num_guest_attivi < 0 ? 'text-danger fw-bold' : ''}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className={"col-12 col-md-7"}>
                                        <ModificaInstallazionePortal installazione={installazione} datiLicenza={datiLicenza} />
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </>
                    : <span>Caricamento in corso</span>
            }

        </Card>

    )

}