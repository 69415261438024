import {TranslateProvider} from "./context/TranslateContext";
import {LoaderProvider} from "./context/LoaderContext";
import {MobileProvider} from "./context/MobileContext";
import {AuthProvider} from "./context/AuthContext";
import {RuoliProvider} from "./context/RuoliContext";

function ProvidersComponent({children}) {
    return (
        <TranslateProvider value={"it"}>
            <LoaderProvider>
                <MobileProvider>
                    <AuthProvider>
                        {/* <RuoliProvider> */}
                            {children}
                        {/* </RuoliProvider> */}
                    </AuthProvider>
                </MobileProvider>
            </LoaderProvider>
        </TranslateProvider>
    )
}

export default ProvidersComponent;