import React, {Fragment, useEffect, useState} from "react";
import Pager from "../Pager";
import Translate from "../Translate";
import NsRowTabellaDesktop from "./NsRowTabellaDesktop";

function NsTabellaDesktop({
                              data,
                              conditionalRowStyles,
                              additionalClasses,
                              onRowClicked,
                              onRowDbClick,
                              columns,
                              funzioneFiltro,
                              componenteFiltro,
                              addPagination = true,
                              addFiltri = true,
                              addDetail = false,
                              getDettaglio
                          }) {

    const recordPerPagina = 30

    const [mappaColOrdinamento, setMappaColOrdinamento] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();


    useEffect(() => {
        var mapOrd = [];

        // Gestione filtro dati
        let valFiltrati = data ? data.filter(
            item => funzioneFiltro ? funzioneFiltro(item, filterText) : true
        ) : [];

        // Gestione sort colonne
        columns.map((colonna, idx) => {
            if (colonna.sortable && colonna.sortable === true) {
                mapOrd[idx] = 'up'
            }
        })
        setMappaColOrdinamento(mapOrd);

        if (addPagination === true) {
            // Totale pagine (massimo record per pagina = 10)
            setTotPagine(Math.ceil(valFiltrati.length / recordPerPagina))

            // Suddivido (ulteriormente) i valori filtrati in base alla pagina
            var start = (pagina - 1) * recordPerPagina
            var end = start + recordPerPagina
            end = valFiltrati.length > end ? end : valFiltrati.length

            var valoriPaginati = []
            for (let i = start; i < end; i++) {
                valoriPaginati.push(valFiltrati[i])
            }

            valFiltrati = valoriPaginati
        }

        setFilteredItems(valFiltrati)
    }, [data, filterText, pagina]);

    const sortFun = (rowA, rowB, idx) => {
        const a = columns[idx].selector(rowA);
        const b = columns[idx].selector(rowB);

        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0
    }

    const sortDati = (idx, sortFunction) => {
        let ord = mappaColOrdinamento[idx]

        if (ord === 'up') {
            if (sortFunction) {
                filteredItems.sort((a, b) => {
                    return sortFunction(a, b)
                })
            } else {
                filteredItems.sort((a, b) => {
                    return sortFun(a, b, idx)
                })
            }
        } else if (ord === 'down') {
            if (sortFunction) {
                filteredItems.sort((a, b) => {
                    return sortFunction(b, a)
                })
            } else {
                filteredItems.sort((a, b) => {
                    return sortFun(b, a, idx)
                })
            }
        }
    }

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    return (
        <Fragment>
            {
                addFiltri === true ?
                    <div className={'row'}>
                        <div className={'col-md-12 text-end'}>
                            {
                                componenteFiltro ?
                                    <div className={'mb-2'}>
                                        {
                                            componenteFiltro()
                                        }
                                    </div>
                                    : <Fragment>
                                        <input
                                            id="search"
                                            type="text"
                                            placeholder="Cerca"
                                            aria-label="Cerca"
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                            className={"ns-filtro-data-table mb-2"}
                                        />
                                    </Fragment>
                            }
                        </div>
                    </div>
                    : ''
            }
            <div className="table-responsive">
                <table className="table table-sm table-hover ns-table-desktop table-striped table-bordered">
                    <thead className="ns-table-head">
                    <tr>
                        {
                            addDetail === true ? <th></th> : ''
                        }
                        {
                            columns.map((colonna, idx) => {
                                return (
                                    <th key={idx} className={"ns-table-th"}
                                        onClick={() => {
                                            if (mappaColOrdinamento[idx]) {
                                                var mapOrd = JSON.parse(JSON.stringify(mappaColOrdinamento))
                                                mapOrd[idx] = mapOrd[idx] === 'up' ? 'down' : 'up'
                                                setMappaColOrdinamento(mapOrd);
                                                sortDati(idx, colonna.sortFunction)
                                            }
                                        }}>
                                        <div className="text-center">
                                        {colonna.name}
                                            {
                                                colonna.sortable && colonna.sortable === true ?
                                                    <span className="ns-sort-colonna-img">
                                                    {
                                                        mappaColOrdinamento[idx] === 'up' ?
                                                            <i className="fas fa-sort-up"/>
                                                            : <i className="fas fa-sort-down"/>
                                                    }
                                                </span>
                                                    : ''
                                            }
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        filteredItems.length === 0 ?
                            <tr>
                                <td colSpan={columns.length + (addDetail === true ? 1 : 0)} >
                                    <Translate>Nessun dato presente</Translate>
                                </td>
                            </tr>
                            : (
                                filteredItems.map((el, idx) => {
                                    var classiAggiuntive = ''

                                    // Controllo se ci sono classi da aggiungere
                                    if (additionalClasses) {
                                        additionalClasses.forEach((condizione) => {
                                            if (condizione.when(el)) {
                                                classiAggiuntive = condizione.classNames;
                                            }
                                        })
                                    }

                                    let style = {};
                                    if (conditionalRowStyles) {
                                        style = conditionalRowStyles(el);
                                    }

                                    return <NsRowTabellaDesktop
                                        key={idx}
                                        el={el}
                                        style={style}
                                        addDetail={addDetail}
                                        classiAggiuntive={classiAggiuntive}
                                        columns={columns}
                                        onRowClicked={onRowClicked}
                                        onRowDbClick={onRowDbClick}
                                        getDettaglio={getDettaglio}
                                        />
                                })
                            )
                    }
                    </tbody>
                </table>
            </div>
            {
                addPagination === true ?
                    <div className={'row'}>
                        <div className={'col-md-12 text-center'}>
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={cambioPagina}
                            >
                            </Pager>
                        </div>
                    </div>
                    : ''
            }
        </Fragment>
    )
}

export default NsTabellaDesktop;