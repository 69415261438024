const Card = ({ children, title, subtitle, icon, getBottoniSx, getBottoniDx }) => {
    return (
        <div className="card cmms-card-portal">
            <div className=" card-body">

                <div className={"row card-title"}>
                    <div className={"col-3  text-start"}>
                        {
                            getBottoniSx ? getBottoniSx() : ''
                        }
                    </div>
                    <div className={"col-6 text-center cmms-card-title-container"}>
                        <h5 className="">
                            <i className={icon} />&nbsp;{title}
                        </h5>
                        {
                            subtitle && subtitle !== '' ?
                                <h6 className="card-subtitle mb-2 cmms_text-muted text-center">
                                    {subtitle}
                                </h6> : ''
                        }
                    </div>
                    <div className={"col-3 text-end"}>
                        {
                            getBottoniDx ? getBottoniDx() : ''
                        }
                    </div>
                </div>

                {children}

            </div>
        </div>
    )
}

export default Card;