import React, {useState, useEffect, useRef, useContext} from "react";
import axios from "axios";
import {Link, useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";

function ModificaInstallazione() {
    const {id} = useParams()

    const iconaDefault = 'fas fa-cogs';

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [optionsPartners, setOptionsPartners] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);


    const history = useHistory();
    const formikRef = useRef();

    useEffect(() => {
        async function getData() {
            startChiamata()
            await axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/" + id)
                .then((response) => {
                    setLoadingData(false);

                    if (formikRef.current) {
                        formikRef.current.setFieldValue("id_installazione", response.data.data.id_installazione)
                        formikRef.current.setFieldValue("descrizione", response.data.data.descrizione)
                        formikRef.current.setFieldValue("endpoint", response.data.data.endpoint)
                        formikRef.current.setFieldValue("id_partner", response.data.data.id_partner)
                        formikRef.current.setFieldValue("email", response.data.data.email)
                    }
                    endChiamata()
                });
        }

        async function getPartners() {
            startChiamata()
            await axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/partner/")
                .then((response) => {
                    setOptionsPartners(response.data.data);
                    endChiamata()
                });
        }

        if (loadingData) {
            getPartners();

            if (id && id !== '') {
                getData();
            }
        }
    }, []);

    const closeModal = () => setShow(false);

    const SignupSchema = Yup.object().shape({
        descrizione: Yup.string()
            .max(200, 'Valore troppo lungo')
            .required('Questo campo è obbligatorio'),
        endpoint: Yup.string()
            .max(100, 'Valore troppo lungo')
            .required('Questo campo è obbligatorio'),
        email: Yup.string()
            .max(2000, 'Valore troppo lungo')
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Card
                        icon={'fas fa-note-sticky'}
                        title={id ? 'Modifica Installazione Oggetto' : 'inserisci Installazione Oggetto'}
                        getBottoniSx={() =>
                            <Link to={"/conf-installazioni"} className="btn btn-card">
                                <i className="fas fa-angle-left"/>
                            </Link>
                        }
                        getBottoniDx={() =>
                            <button type="button" id="salva" name="salva"
                                    className="btn btn-card"
                                    onClick={() => {
                                        if (formikRef.current) {
                                            formikRef.current.handleSubmit()
                                        }
                                    }}>
                                <i className="fas fa-save"/> <span className={'cmms-display-mobile'}>Salva</span>
                            </button>
                        }>
                        <Formik
                            initialValues={{
                                id_installazione: '',
                                descrizione: '',
                                endpoint: '',
                                id_partner: '',
                                email: ''
                            }}
                            innerRef={formikRef}
                            validationSchema={SignupSchema}
                            onSubmit={values => {
                                if (id) {
                                    startChiamata()
                                    axios
                                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/" + id,
                                            values)
                                        .then((response) => {
                                            if (response.data.result === 0) {
                                                history.push("/conf-installazioni");
                                            } else {
                                                setModalMsg(response.data.description);
                                                setShow(true);
                                            }
                                            endChiamata()
                                        });
                                } else {
                                    startChiamata()
                                    axios
                                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/",
                                            values)
                                        .then((response) => {
                                            if (response.data.result === 0) {
                                                history.push("/conf-installazioni");
                                            } else {
                                                setModalMsg(response.data.description);
                                                setShow(true);
                                            }
                                            endChiamata()
                                        });
                                }
                            }}
                        >
                            {({errors, touched, values}) => (
                                <Form className="row g-3">

                                    <div className="col-md-4">
                                        <label htmlFor="descrizione" className="form-label">Descrizione*:</label>
                                        <Field name="descrizione" className="form-control"/>
                                        {errors.descrizione && touched.descrizione ? (
                                            <div className="cmms-div-err-form">{errors.descrizione}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="endpoint" className="form-label">Endpoint:</label>
                                        <Field name="endpoint" className="form-control"/>
                                        {errors.endpoint && touched.endpoint ? (
                                            <div className="cmms-div-err-form">{errors.descrizione}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="id_partner" className="form-label">Partner:</label>
                                        <Field as="select"
                                               name="id_partner"
                                               className={'form-select'}>
                                            <option value={""}>Selezionare un partner</option>
                                            {
                                                optionsPartners.map((op, index) => {
                                                    return <option key={index}
                                                                   value={op.id_partner}>{op.descrizione}</option>
                                                })
                                            }
                                        </Field>
                                        {errors.id_partner && touched.id_partner ? (
                                            <div className="cmms-div-err-form">{errors.id_partner}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="email" className="form-label">E-mail cliente (separate da ";"):</label>
                                        <Field as="textarea"
                                               name="email"
                                               className={'form-control'}/>
                                        {errors.email && touched.email ? (
                                            <div className="cmms-div-err-form">{errors.email}</div>
                                        ) : null}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default ModificaInstallazione;