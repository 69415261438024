import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from "yup";
import Card from "../../../components/Card";
import ModalMsg from "../../../components/ModalMsg";
import { LoaderContext } from "../../../context/LoaderContext";
import { RuoliContext } from "../../../context/RuoliContext";

function ModificaUtente() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [idUsr, setIdUsr] = useState(id);

    // carico riferimento al contesto in modo da poterlo usare correttamente in useEffect senza avere problemi di dipendenze non dichiarate
    const loader = useRef(useContext(LoaderContext));

    const history = useHistory();
    const formikRef = useRef();

    useEffect(() => {

        if (loadingData) {
            if (idUsr && idUsr !== '') {
                loader.current.startChiamata();
                axios
                    .get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/" + idUsr)
                    .then((response) => {
                        let val = response.data.data;
                        setLoadingData(false);

                        if (formikRef.current) {
                            formikRef.current.setFieldValue("id", val.id);
                            formikRef.current.setFieldValue("nome", val.nome);
                            formikRef.current.setFieldValue("cognome", val.cognome);
                            formikRef.current.setFieldValue("mail", val.mail);
                        }
                        loader.current.endChiamata();
                    });
            }
        }
    }, [idUsr, id, loadingData]);

    const SignupSchema = Yup.object().shape({
        nome: Yup.string()
            .max(150, 'Valore troppo lungo')
            .required('Questo campo è obbligatorio'),
        cognome: Yup.string()
            .max(150, 'Valore troppo lungo')
            .required('Questo campo è obbligatorio'),
        mail: Yup.string()
            .email('Indirizzo E-mail non valido')
            .required('Questo campo è obbligatorio'),
    });

    const closeModal = () => setShow(false);

    const funzioneFiltro = (item, filtro) => {
        return (item.codice && item.codice.toLowerCase().includes(filtro.toLowerCase()))
            || (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
    }

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Card
                        icon={'fas fa-user'}
                        title={idUsr ? 'Modifica Utente' : 'Inserisci Utente'}
                        getBottoniSx={() =>
                            <Link to={"/conf-utenti"} className="btn btn-card">
                                <i className="fas fa-angle-left"/>
                            </Link>
                        }
                        getBottoniDx={() =>
                            <button type="button" id="salva" name="salva"
                                    className="btn btn-card"
                                    onClick={() => {
                                        if (formikRef.current) {
                                            formikRef.current.handleSubmit()
                                        }
                                    }}>
                                <i className="fas fa-save"/> <span className={'cmms-display-mobile'}>Salva</span>
                            </button>
                        }>
                        <Formik
                            initialValues={{
                                id: '',
                                nome: '',
                                cognome: '',
                                mail: ''
                            }}
                            innerRef={formikRef}
                            validationSchema={SignupSchema}
                            onSubmit={values => {
                                if (idUsr) {
                                    loader.current.startChiamata();
                                    axios
                                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/" + idUsr,
                                            values)
                                        .then((response) => {
                                            if (response.data.result === 0) {
                                                loader.current.endChiamata();
                                                history.goBack();
                                            } else {
                                                setModalMsg(response.data.description);
                                                setShow(true);
                                                loader.current.endChiamata();
                                            }
                                        });
                                } else {
                                    loader.current.startChiamata();
                                    axios
                                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/",
                                            values)
                                        .then((response) => {
                                            if (response.data.result === 0) {
                                                loader.current.endChiamata();
                                                //setIdUsr(response.data.data.id);
                                                history.goBack();
                                            } else {
                                                setModalMsg(response.data.description);
                                                setShow(true);
                                                loader.current.endChiamata();
                                            }
                                        });
                                }
                            }}
                        >
                            {({errors, touched}) => (
                                <Form className="row g-3">
                                    <div className="col-md-12">
                                        <label htmlFor="mail" className="form-label">E-mail*:</label>
                                        <Field
                                            name="mail"
                                            type="mail"
                                            className="form-control"
                                            readOnly={idUsr}
                                        />
                                        {errors.mail && touched.mail ? (
                                            <div className="cmms-div-err-form">{errors.mail}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="nome" className="form-label">Nome*:</label>
                                        <Field
                                            name="nome"
                                            className="form-control"
                                        />
                                        {errors.nome && touched.nome ? (
                                            <div className="cmms-div-err-form">{errors.nome}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="cognome" className="form-label">Cognome*:</label>
                                        <Field
                                            name="cognome"
                                            className="form-control"
                                        />
                                        {errors.cognome && touched.cognome ? (
                                            <div className="cmms-div-err-form">{errors.cognome}</div>
                                        ) : null}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default ModificaUtente;