import React, {Fragment, useContext} from "react";
import NsTabellaMobile from "./NsTabellaMobile";
import NsTabellaDesktop from "./NsTabellaDesktop";
import {MobileContext} from "../../context/MobileContext";

function NsDataTable({
                           data,
                           columns,
                           funzioneFiltro,
                           componenteFiltro,
                           additionalClasses,
                           conditionalRowStyles,
                           onRowClicked,
                           onRowDbClick,
                           addPagination = true,
                           addFiltri = true,
                           addDetail = false,
                         getDettaglio
                       }) {

    const {isMobile} = useContext(MobileContext);

    return (
        <Fragment>
            {
                isMobile ?
                    <NsTabellaMobile
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        additionalClasses={additionalClasses}
                        onRowClicked={onRowClicked}
                        onRowDbClick={onRowDbClick}
                        data={data}
                        addDetail={addDetail}
                        getDettaglio={getDettaglio}
                    />
                    : <NsTabellaDesktop
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        additionalClasses={additionalClasses}
                        onRowClicked={onRowClicked}
                        onRowDbClick={onRowDbClick}
                        data={data}
                        addDetail={addDetail}
                        addFiltri={addFiltri}
                        addPagination={addPagination}
                        funzioneFiltro={funzioneFiltro}
                        componenteFiltro={componenteFiltro}
                        getDettaglio={getDettaglio}
                    />
            }
        </Fragment>
    );

}

export default NsDataTable;