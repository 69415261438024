import Card from "../../../components/Card";
import {useFormik} from "formik";
import axios from "axios";
import ModalMsg from "../../../components/ModalMsg";
import React, {useContext, useState} from "react";
import {LoaderContext} from "../../../context/LoaderContext";
import {Link} from "react-router-dom";

function RecuperaPwd() {

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const closeModal = () => setShow(false);

    const formik = useFormik({
        initialValues: {
            mail: ''
        }, onSubmit: async values => {
            startChiamata()
            await axios
                .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/password/mail/",
                    values)
                .then((response) => {
                    setModalMsg(response.data.description);
                    setShow(true);
                    endChiamata()
                });
        }
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className="cmms-login cmms-recupera-pwd">
                <Card
                    icon={'fas fa-key'}
                    title={'Recupera password'}
                    subtitle={'Inserisci il tuo indirizzo e-mail e ti verrà inviato un link per cambiare la tua password'}>
                    <form className="row g-3 needs-validation"
                          method="post"
                          noValidate=""
                          onSubmit={formik.handleSubmit}>
                        <div className="col-12">
                            <label htmlFor="mail" className="form-label">E-mail</label>
                            <input
                                id="mail"
                                name="mail"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.mail}
                                className="form-control"
                                required/>
                            <div className="invalid-feedback">
                                L'indirizzo E-Mail deve essere valorizzato.
                            </div>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" name="conferma" id="conferma" className="btn btn-dark">
                                <i className="fas fa-user-lock" /> Conferma
                            </button>
                            <Link to="/" className="btn btn-dark">
                                <i className="fas fa-sign-in-alt"/> Login
                            </Link>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default RecuperaPwd;
