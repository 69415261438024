import { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer className="cmms-footer mt-auto py-1 fixed-bottom">
                <div className="container-fluid text-center">
                    <span>© Manù All rights reserved. Powered by Netsurf</span>
                </div>
            </footer>
        );
    }
}

export default Footer;
