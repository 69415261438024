import React from "react";

function CardLicenza({titolo, num, tot, nome_class}) {

    return (
        <div className={'row text-center cmms-card-licenza-portal w-100 h-100'}>
            <div className={'col-12 mt-1'}>
                {/* Utenze  */}
                <strong>{titolo}</strong>:
            </div>
            <div className={`col-12  mb-2 cmms-dati-licenza-num-portal ${nome_class}`}>
                {
                    num + ' / '
                }
                <strong>
                    {
                        tot
                    }
                </strong>
            </div>
        </div>
    )
}

export default CardLicenza;