import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import {Link} from "react-router-dom";
import ModalConfirm from "../../../components/ModalConfirm";
import ModalMsg from "../../../components/ModalMsg";
import {Tab, Tabs} from "react-bootstrap";
import DataTableCmms from "../../../components/tabelle/NsDataTable";

function ConfPartners() {

    const [loadingData, setLoadingData] = useState(true);

    const [data, setData] = useState([]);
    const [PartnerSel, setPartnerSel] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [key, setKey] = useState('partners');    

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    useEffect(() => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/partner/").then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }, []);

    const openModalDel = (partner) => {
        setPartnerSel(partner)
        setShowConfirm(true);
    }

    const closeModalDel = () => setShowConfirm(false);
    const closeModal = () => setShow(false);

    const eliminaPartner = () => {
        setShowConfirm(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/partner/" + PartnerSel.id_partner).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                setData(response.data.data);
            }
            endChiamata()
        });
    }

    const funzioneFiltro = (item, filtro) => {
        return (item.codice && item.codice.toLowerCase().includes(filtro.toLowerCase()))
            || (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
    }

    const columns = [
        {
            name: 'Descrizione',
            selector: row => row.descrizione,
            sortable: true,
        },
        {
            name: '',
            selector: row => <Fragment>
                <Link to={"/upd-partner/" + row.id_partner}
                      className="btn btn-dark cmms-btn-table"
                      data-toggle="tooltip" title="Modifica">
                    <i className="fas fa-pen"/> <span className={'cmms-display-mobile'}>Modifica</span>
                </Link>
                <button className="btn btn-danger cmms-btn-table"
                        data-toggle="tooltip" title="Elimina" onClick={() => {
                    openModalDel(row)
                }}>
                    <i className="fas fa-trash"/> <span className={'cmms-display-mobile'}>Elimina</span>
                </button>
            </Fragment>,
            sortable: false,
            right: true,
        }
    ];

    return (
        <CardContainer>
            <Card
                icon={'fas fa-users'}
                title={'Partners'}
                getBottoniDx={() => {
                return <Link to="/upd-partner" className={"btn btn-card"}>
                    <i className="fas fa-plus-circle"/> <span className={'cmms-display-mobile'}>Aggiungi</span>
                </Link>
                }}>
                <ModalConfirm
                    show={showConfirm}
                    handleClose={closeModalDel}
                    handleOk={eliminaPartner}
                    testo={"Sicuro di voler eliminare l'Partner: " + (PartnerSel && PartnerSel.id_partner) + "?"}/>
                <ModalMsg
                    show={show}
                    handleClose={closeModal}
                    testo={modalMsg}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <Fragment>
                            <DataTableCmms
                                data={data}
                                columns={columns}
                                funzioneFiltro={funzioneFiltro}
                            >
                            </DataTableCmms>
                        </Fragment>
                    )
                }
            </Card>
        </CardContainer>
    );
}

export default ConfPartners;
