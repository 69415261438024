import axios from "axios";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import InfoCharts from "./InfoCharts";
import DataTableCmms from "../../components/tabelle/NsDataTable";


import {getDataFormattata, dateToMilliseconds, scadenza_warning, getTimestampFormattato} from "../../utility/common";

import {Tooltip as ReactTooltip} from "react-tooltip";


function DashboardStatiInstallazioni() {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [installazioni, setInstallazioni] = useState([]);
    const [mappaDati, setMappaDati] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);


    const [InstallazioneSel, setInstallazioneSel] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const openModalDel = (installazione) => {
        setInstallazioneSel(installazione)
        setShowConfirm(true);
    }


    useEffect(() => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/").then((response) => {
            let inst = response.data.data
            if (inst) {
                setInstallazioni(inst);
                setLoadingData(false);

                inst.forEach(inst => {
                    axios.get(inst.endpoint + "api/cmms/info/licenza/").then((response) => {
                        let dataInst = response.data;

                        if (dataInst) {
                            setMappaDati(precMappaInfo => ({...precMappaInfo, [inst.id_installazione]: dataInst}));
                        }

                        endChiamata()
                    });
                });
            }

            endChiamata()
        });
    }, []);

    console.log(mappaDati)


    return (
        <Card
            icon={'fas fa-briefcase'}
            title={'Info Installazioni'}
        >
            <DataTableCmms
                data={installazioni}
                columns={[
                    {
                        name: 'Installazione',
                        selector: row => <div className="text-center">{row.descrizione}</div>,
                        sortable: true,

                    },
                    {
                        name: 'Partner',
                        selector: row => <div className="text-center">{row.partner}</div>,
                        sortable: true,

                    },
                    {
                        name: 'Super Admin',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_superad - mappaDati[row.id_installazione].num_superad_attivi < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_superad - mappaDati[row.id_installazione].num_superad_attivi === 0) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].num_superad_attivi + '/' + mappaDati[row.id_installazione].num_superad) : '-'}</div>,
                        sortable: false,
                    },
                    {
                        name: 'Admin',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_admin - mappaDati[row.id_installazione].num_admin_attivi < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_admin - mappaDati[row.id_installazione].num_admin_attivi === 0) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].num_admin_attivi + '/' + mappaDati[row.id_installazione].num_admin) : '-'}</div>,
                        sortable: false,
                    },
                    {
                        name: 'Tecnico',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_tecnici - mappaDati[row.id_installazione].num_tecnici_attivi < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_tecnici - mappaDati[row.id_installazione].num_tecnici_attivi === 0) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].num_tecnici_attivi + '/' + mappaDati[row.id_installazione].num_tecnici) : '-'}</div>,
                        sortable: false,
                    },
                    {
                        name: 'Guest',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_guest - mappaDati[row.id_installazione].num_guest_attivi < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].num_guest - mappaDati[row.id_installazione].num_guest_attivi === 0) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].num_guest_attivi + '/' + mappaDati[row.id_installazione].num_guest) : '-'}</div>,
                        sortable: false,
                    },
                    {
                        name: 'Spazio (GB)',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].max_space_disk - mappaDati[row.id_installazione].spazio_libero_gb < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && (mappaDati[row.id_installazione].max_space_disk - mappaDati[row.id_installazione].spazio_libero_gb === 0) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].spazio_libero_gb + '/' + mappaDati[row.id_installazione].max_space_disk) : '-'}</div>,
                        sortable: true,
                        sortFunction: (a, b) => {
                            let dataA = mappaDati[a.id_installazione].spazio_libero_gb;
                            let dataB = mappaDati[b.id_installazione].spazio_libero_gb;

                            if (dataA < dataB) {
                                return -1;
                            }

                            if (dataA > dataB) {
                                return 1;
                            }

                            return 0;
                        }
                    },
                    {
                        name: 'Data scadenza',
                        selector: row => <div
                            className={` text-center ${mappaDati[row.id_installazione] && (dateToMilliseconds(mappaDati[row.id_installazione].data_scadenza) - dateToMilliseconds(Date.now()) < 0) ? 'text-danger fw-bold' :
                                mappaDati[row.id_installazione] && ((dateToMilliseconds(mappaDati[row.id_installazione].data_scadenza) - dateToMilliseconds(Date.now())) < scadenza_warning(1)) ? 'text-warning fw-bold' : ''}`}
                        >{mappaDati[row.id_installazione] ? getDataFormattata(mappaDati[row.id_installazione].data_scadenza) : '-'}</div>,
                        sortable: true,
                        sortFunction: (a, b) => {
                            let dataA = dateToMilliseconds(mappaDati[a.id_installazione].data_scadenza);
                            let dataB = dateToMilliseconds(mappaDati[b.id_installazione].data_scadenza);

                            if (dataA < dataB) {
                                return -1;
                            }

                            if (dataA > dataB) {
                                return 1;
                            }

                            return 0;
                        }
                    },
                    {
                        name: 'Ultimo login',
                        selector: row => <div
                            className={` text-center `}
                        >{mappaDati[row.id_installazione] && mappaDati[row.id_installazione].ultimo_login && mappaDati[row.id_installazione].ultimo_login !== ''
                            ? getTimestampFormattato(mappaDati[row.id_installazione].ultimo_login) : '-'}</div>,
                        sortable: true,
                        sortFunction: (a, b) => {
                            if (mappaDati[a.id_installazione].ultimo_login && mappaDati[a.id_installazione].ultimo_login !== ''
                                && mappaDati[b.id_installazione].ultimo_login && mappaDati[b.id_installazione].ultimo_login !== '') {
                                let dataA = dateToMilliseconds(mappaDati[a.id_installazione].ultimo_login);
                                let dataB = dateToMilliseconds(mappaDati[b.id_installazione].ultimo_login);

                                if (dataA < dataB) {
                                    return -1;
                                }

                                if (dataA > dataB) {
                                    return 1;
                                }
                            }
                            return 0;
                        }
                    },
                    {
                        name: 'Versione',
                        selector: row => <div
                            className="text-center">{mappaDati[row.id_installazione] ? (mappaDati[row.id_installazione].versione) : '-'}</div>,
                        sortable: true,
                        sortFunction: (a, b) => {
                            let A = dateToMilliseconds((mappaDati[a.id_installazione].versione).split(" ")[1]);
                            let B = dateToMilliseconds((mappaDati[a.id_installazione].versione).split(" ")[1]);
                            if (A < B) {
                                return -1;
                            }

                            if (A > B) {
                                return 1;
                            }

                            return 0;
                        }
                    },

                    {
                        name: '',
                        selector: row => <div className="text-center">
                            <Link to={"/charts-installazione/" + row.id_installazione + '/' + row.descrizione}
                                  className="btn btn-dark cmms-btn-table"
                                  data-toggle="tooltip" data-tooltip-id="visualizza">
                                <span className={'cmms-display-mobile'}></span>

                                <span className="fa-stack small">
                                    <i className="fas fa-eye"></i>
                                </span>

                            </Link>
                            <ReactTooltip
                                id="visualizza"
                                place="top"
                                content="Clicca per visualizzare lo stato dell'installazione e/o modificare i suoi parametri"
                                style={{backgroundColor: "rgba(53,92,151,0.8)", color: "#fff", width: "200px"}}
                                className={"text-center"}
                            />
                        </div>,
                        sortable: false,
                        right: true,
                    }
                ]}
                funzioneFiltro={(item, filtro) => {
                    return (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
                }}

            >
            </DataTableCmms>

        </Card>
    );
}

export default DashboardStatiInstallazioni;