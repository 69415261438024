import {Modal} from "react-bootstrap";
import {useContext} from "react";
import {TranslateContext} from "../context/TranslateContext";

function ModalMsg({show, handleClose, titolo, testo}) {

    const {traduci} = useContext(TranslateContext)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header
                className={'cmms-modal-header'}
                closeVariant='white'
                closeButton >
                <Modal.Title>{titolo || traduci('ATTENZIONE')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{testo}</p>
            </Modal.Body>
        </Modal>
    )

}

export default ModalMsg;