import React, {Fragment, useState} from "react";

function NsRowTabellaDesktop({
                                 classiAggiuntive,
                                 style,
                                 el,
                                 onRowClicked,
                                 onRowDbClick,
                                 columns,
                                 addDetail = false,
                                 getDettaglio
                             }) {

    const [aperto, setAperto] = useState(false);

    return (
        <Fragment>
            <tr
                className={classiAggiuntive}
                style={style}
                onClick={() => {
                    if (onRowClicked) {
                        onRowClicked(el)
                    }
                }}
                onDoubleClick={() => {
                    if (onRowDbClick) {
                        onRowDbClick(el)
                    }
                }}
            >
                {
                    addDetail === true ?
                        <td>
                            <button type="button" className="btn btn-dark rfi-btn-sm" onClick={() => {
                                setAperto(!aperto)
                            }}>
                                <i className={"fas fa-" + (aperto === true ? 'minus' : 'plus')}></i>
                            </button>
                        </td>
                        : ''
                }
                {
                    columns.map((col, idxCol) => {
                        return (
                            <td key={idxCol}
                                className={col.right && col.right === true ? 'text-end' : 'text-start'}>
                                {
                                    col.selector(el)
                                }
                            </td>
                        )
                    })
                }
            </tr>
            {
                addDetail === true && aperto === true ?
                    <tr>
                        <td colSpan={columns.length + 1} className={"ns-riga-dettaglio"}>
                            {
                                getDettaglio(el)
                            }
                        </td>
                    </tr>
                    : ''
            }
        </Fragment>
    )
}

export default NsRowTabellaDesktop;