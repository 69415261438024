import React, {Fragment} from "react";
import {Field} from "formik";
import DatePicker from "react-datepicker";
import {it} from "date-fns/locale";

export const mesiIt = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
export const mesiBreviIt = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
export const giorniIt = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica']
// const giorniBreviIt = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']

export function getUtenteFromToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var usr = JSON.parse(jsonPayload);
    return usr && usr.user;
}

export function getTimestampFormattato(data) {
    if (!data || data === '') {
        return '';
    }
    return data.substring(8, 10) + '/' + data.substring(5, 7) + '/' + data.substring(0, 4) + (data.length > 10 ? data.substring(10, 16) : '');
}

export function getDataFormattata(data) {
    if (!data || data === '') {
        return '';
    }
    return data.substring(8, 10) + '/' + data.substring(5, 7) + '/' + data.substring(0, 4);
}

export function getDataPerDb(data) {
    if (!data) {
        return null;
    }
    return data.substring(6, 10) + '-' + data.substring(3, 5) + '-' + data.substring(0, 2);
}

export function convertiDateInDataPerDb(data) {
    if (data) {
        data = new Date(data)
    }

    return data ? [
        data.getFullYear(),
        (data.getMonth() + 1).toString().padStart(2, '0'),
        data.getDate().toString().padStart(2, '0')
    ].join('-') : null;
}

export function convertiTimestampInDataPerDb(data) {
    let d = convertiDateInDataPerDb(data)
    if (d) {
        if (data) {
            data = new Date(data)
        }

        d += ' ' + [
            data.getHours().toString().padStart(2, '0'),
            data.getMinutes().toString().padStart(2, '0'),
            data.getSeconds().toString().padStart(2, '0')
        ].join(':')
    }
    return d;
}

export function getLunedi(data) {
    var d = new Date(data.getTime());
    var day = d.getDay()
    var diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function getDomenica(d) {
    var lunedi = getLunedi(d);
    var diff = lunedi.getDate() + 6;
    return new Date(d.setDate(diff));
}

export function aggiungiGiorno(data) {
    var d = new Date(data.getTime());
    var diff = d.getDate() + 1;
    return new Date(d.setDate(diff));
}

export function sottraiGiorno(data) {
    return sottraiGiorni(data, 1);
}

export function sottraiGiorni(data, giorni) {
    var d = new Date(data.getTime());
    var diff = d.getDate() - giorni;
    return new Date(d.setDate(diff));
}

export function aggiungiMese(data) {
    var d = new Date(data.getTime());
    return new Date(d.setMonth(d.getMonth() + 1));
}

export function sottraiMese(data) {
    var d = new Date(data.getTime());
    return new Date(d.setMonth(d.getMonth() - 1));
}

export function getDataJsFormattata(d) {
    return [
        d.getDate().toString().padStart(2, '0'),
        (d.getMonth() + 1).toString().padStart(2, '0'),
        d.getFullYear()
    ].join('/')
}

export function getTimeDataJs(d) {
    let date = new Date(d);
    date.setHours(0, 0, 0, 0);
    return date.getTime()
}

export function getAnno(d) {
    let date = new Date(d);
    date.setDate(1)
    return date.getFullYear()
}

export function getMese(d) {
    let date = new Date(d);
    date.setDate(1)
    let mese = date.getMonth();
    return mesiIt[mese] + ' ' + date.getFullYear()
}

export function getMeseAbbreviato(d) {
    let date = new Date(d);
    date.setDate(1)
    let mese = date.getMonth();
    return mesiBreviIt[mese]
}

export function getGiorno(d) {
    let date = new Date(d);
    let giorno = date.getDay();
    return giorniIt[giorno]
}

/*----------------------------------------------*/

export function dateToMilliseconds(str){
    let d = new Date(str);
    return d.getTime();
}
/*------------------------------------------------------*/


export function getIconaOggetto(obj) {
    return <i className={obj.icona_classe}/>
}

export function getStrRgba(strColore) {
    if (!strColore || strColore === '') {
        strColore = '{"r":255,"g":255,"b":255,"a":1}';
    }
    let colore = JSON.parse(strColore);
    return "rgba("
        + colore.r + ", "
        + colore.g + ", "
        + colore.b + ", "
        + colore.a + ")";
}

/**
 * Restituisce l'elemento jsx (`Fragment`) con i punti esclamativi colorati associati alla priorità in input
 * @param {int} priorita priorità dell'odl come int(1, 2, 3)
 * @returns L'elemento jsx (`Fragment`) con i punti esclamativi colorati associati alla priorità in input
 */
export function getPriorita(priorita) {
    
    let prioritaInt = parseInt(priorita);

    if (prioritaInt === 1) {
        return <i className = "fas fa-exclamation cmms-color cmms-color--verde" ></i>
    } else if (prioritaInt === 2) {
        return <Fragment>
            <i className="fas fa-exclamation cmms-color cmms-color--giallo"></i>
            <i className="fas fa-exclamation cmms-color cmms-color--giallo"></i>
        </Fragment>
    } else if (prioritaInt === 3) {
        return <Fragment>
            <i className="fas fa-exclamation cmms-color cmms-color--rosso"></i>
            <i className="fas fa-exclamation cmms-color cmms-color--rosso"></i>
            <i className="fas fa-exclamation cmms-color cmms-color--rosso"></i>
        </Fragment>
    }
}

/**
 * Recupera descrizione in stringa della priorità (int) dell'odl. 
 * Utile per testi, classi css ecc
 * @param {int} priorita priorità dell'odl come int (1, 2, 3)
 * @returns Descrizione in string della priorità (`Alta`, `Media`, `Bassa`)
 */
export function getPrioritaDesc(priorita) {

    let prioritaInt = parseInt(priorita);

    if (prioritaInt === 1) {
        return 'Bassa'
    } else if (prioritaInt === 2) {
        return 'Media';
    } else if (prioritaInt === 3) {
        return 'Alta';
    }

    return '';
}

/**
 * Restituisce il colore della riga di dashboard odl come stringa da passare al css, in base al tipo in input
 * @param {*} odl 
 * @param {int} colorType valore per determinare quale strategia utilizzare per colorare la riga, in genere recuperato dalle impostazioni.
 * `0`: Colore come riga (default, gestito separatamente), `1`: Priorità, `2`: Tipo
 * @returns 
 */
export function getOdlRowColor(odl, colorType) {
    let color = '';
    let colorString = '';

    // vado di default su colore come riga (gestito separatamente)
    colorType = parseInt(colorType) || 0;

    if (colorType === 1) {
        let priority = parseInt(odl.priorita);

        if (priority === 1) {
            // verde
            color = {r: 106, g: 203, b: 106, a: 0.25};
        } else if (priority === 2) {
            // giallo
            color = {r: 239, g: 199, b: 61, a: 0.25};
        } else if (priority === 3) {
            // rosso
            color = {r: 236, g: 47, b: 47, a: 0.25};
        }

        colorString = JSON.stringify(color);
    } else if (colorType === 2) {
        colorString = odl.colore;
    }

    return getStrRgba(colorString);
}

export function getCampoCustom(campo, formikRef, errors, touched, values) {
    if (campo.campo_tabella.indexOf('campo_str') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            <Field name={campo.campo_tabella}
                   className="form-control"
                   disabled={parseInt(campo.editabile) !== 1}
                   required={parseInt(campo.obbligatorio) === 1}
                   validate={(value) => {
                       if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                            return 'Questo campo è obbligatorio'
                       }
                   }}
            />
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else if (campo.campo_tabella.indexOf('campo_int') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            <Field name={campo.campo_tabella}
                   type="number"
                   className="form-control"
                   disabled={parseInt(campo.editabile) !== 1}
                   required={parseInt(campo.obbligatorio) === 1}
                   validate={(value) => {
                       if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                           return 'Questo campo è obbligatorio'
                       }
                   }}/>
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else if (campo.campo_tabella.indexOf('campo_float') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            <Field name={campo.campo_tabella}
                   type="number"
                   step="0.01"
                   className="form-control"
                   disabled={parseInt(campo.editabile) !== 1}
                   required={parseInt(campo.obbligatorio) === 1}
                   validate={(value) => {
                       if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                           return 'Questo campo è obbligatorio'
                       }
                   }}/>
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else if (campo.campo_tabella.indexOf('campo_boolean') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            &nbsp;
            <Field type="checkbox"
                   name={campo.campo_tabella}
                   className="form-check-input"
                   required={parseInt(campo.obbligatorio) === 1}
                   disabled={parseInt(campo.editabile) !== 1}
                   onChange={e => formikRef.current.setFieldValue(campo.campo_tabella, e.target.checked ? '1' : '0')}
                   checked={values[campo.campo_tabella] === '1'}
                   validate={(value) => {
                       if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                           return 'Questo campo è obbligatorio'
                       }
                   }}/>
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else if (campo.campo_tabella.indexOf('campo_date') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            <Field
                name={campo.campo_tabella}
                component={DatePicker}
                locale={it}
                selected={values[campo.campo_tabella] && new Date(values[campo.campo_tabella])}
                onChange={(date) => {
                    formikRef.current.setFieldValue(campo.campo_tabella, date)
                }}
                onSelect={(date) => {
                    formikRef.current.setFieldValue(campo.campo_tabella, date)
                }}
                canBeDisabled={true}
                className="form-control"
                required={parseInt(campo.obbligatorio) === 1}
                disabled={parseInt(campo.editabile) !== 1}
                autoComplete="off"
                dateFormat={"dd/MM/yyyy"}
                validate={(value) => {
                    if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                        return 'Questo campo è obbligatorio'
                    }
                }}
            />
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else if (campo.campo_tabella.indexOf('campo_timestamp') > -1) {
        return (<div className="col-md-6" key={campo.id}>
            <label htmlFor={campo.campo_tabella}
                   className="form-label">{campo.label + (parseInt(campo.obbligatorio) === 1 ? ' *:' : ':')}</label>
            <Field
                name={campo.campo_tabella}
                component={DatePicker}
                locale={it}
                showTimeSelect
                timeCaption={"Ora"}
                selected={values[campo.campo_tabella] && new Date(values[campo.campo_tabella])}
                onChange={(date) => {
                    formikRef.current.setFieldValue(campo.campo_tabella, date)
                }}
                onSelect={(date) => {
                    formikRef.current.setFieldValue(campo.campo_tabella, date)
                }}
                canBeDisabled={true}
                className="form-control"
                required={parseInt(campo.obbligatorio) === 1}
                disabled={parseInt(campo.editabile) !== 1}
                autoComplete="off"
                dateFormat={"dd/MM/yyyy HH:mm:ss"}
                validate={(value) => {
                    if (parseInt(campo.obbligatorio) === 1 && (!value || value === '')) {
                        return 'Questo campo è obbligatorio'
                    }
                }}
            />
            {errors[campo.campo_tabella] && touched[campo.campo_tabella] ? (
                <div
                    className="cmms-div-err-form">{errors[campo.campo_tabella]}</div>
            ) : null}
        </div>)
    } else {
        return ''
    }
}

export function getAnni(startYear, anniInFuturo = 0) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1920;
    while (startYear <= (currentYear + anniInFuturo)) {
        years.push(startYear++);
    }
    return years;
}

export function handleMoneyChange(e){
    if(e.key !== "Backspace"){
        if(e.target.value.includes('.')){
            if(e.target.value.split('.')[1].length >= 2){
                var num = parseFloat(e.target.value);
                e.target.value = num.toFixed(2);
                e.preventDefault();
            }
        }
    }
}

export function scadenza_warning(num){
    return (num * 30 * 24 * 60 * 60 * 1000);
}